<template>
  <div v-if="is_missing" class="block national-system">
    <h3 class="subtitle is-5 has-text-danger">
      <font-awesome-icon icon="exclamation-triangle"/>
      {{ title }}
    </h3>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    filter: Function,
    scanResults: Array
  },
  computed: {
    is_missing () {
      return this.scanResults.filter(r => this.filter(r)).length === 0
    }
  }
}
</script>